
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'squareDetail'
})

export default class extends Vue {
  private detail: any = {}
  private centerDialogVisible = false
  private detailList = [
    {
      img: require('@/assets/icon/square/zhihuipengguan.svg'),
      name: '智慧喷灌',
      introduce: '实时监测土壤环境温度、湿度、降雨量、等数据，通过智慧喷灌模型精准分析预测，向终端自动下达正确的喷灌决策，智能补充植物所需水份',
      productIntroduce: '通过监测土壤温度、湿度、天气降雨情况等多维度建立喷灌数据模型，智能化进行浇灌，降低人力成本的同时，降低了水电成本，并通过科学浇灌，提高了苗木存活率，达到无人化精准灌溉；喷灌系统包含web端及app端操作系统，功能包含喷灌配置、数据查看、喷灌操作等',
      useIntroduceList: [
        {
          label: '喷灌设备配置：',
          content: '可针对喷灌实际部署区域进行区域配置，各区域关联喷灌电磁阀、土壤传感器、水表、电表设备，进行该区域喷灌的数据监测及开启关闭判断，同时可进行查看喷灌电磁阀所在地图位置并进行手动开启。'
        },
        {
          label: '喷灌智能计划：',
          content: '可针对不同区域喷灌配置相应的智能计划，计划可配置开启电磁阀的并发数、计划执行的日期周期、喷灌时长、智能计划触发的阈值、计划的开启关闭操作等；智能计划开启后将通过设备监测及数据模型运算判断是否触发阈值进行自动喷灌，达到智慧灌溉的效果。'
        },
        {
          label: '喷灌定时计划：',
          content: '可针对不同区域喷灌设置定时计划，即根据定时开启的时间及开启的周期进行配置，当达到相应日期时间时喷灌自动开启进行浇灌。'
        },
        {
          label: '喷灌记录：',
          content: '统计喷灌系统中各个电磁阀设备的开启、关闭记录信息。'
        }
      ],
      hardwareList: ['土壤监测器', '智慧水表', '智能电表', '环境监测'],
      softwareList: ['智慧喷灌', '土壤监测', '智能电表', '智慧水表'],
      codes: ['yhIrrigation', 'yhSoil']
    },
    {},
    {},
    {
      img: require('@/assets/icon/square/renyuan.svg'),
      name: '人员智能监管',
      introduce: '基于项目养护工人管理需求，通过智能硬件实现实时定位，规范作业；基于人脸识别AI技术实现项目实名制考勤及上工人数智能计算',
      productIntroduce: '通过智能手环、工牌设备，实现查看养护工人实时位置、工人分布情况、工人在岗人数、工人移动轨迹等功能。提供移动端APP用于现场养护工人分布和轨迹数据查看，同时提供电脑端WEB进行养护工人管理和数据统计查看；基于人脸识别AI技术，实现项目工人实名制考勤管理以及项目上工人数智能计算',
      useIntroduceList: [
        {
          label: '实时定位：',
          content: '通过人员绑定的智能设备按时上报的点位坐标信息，在地图中实时标记人员定位信息，点击图标可显示人员信息'
        },
        {
          label: '轨迹回放：',
          content: '通过查询人员绑定的智能设备历史上报点位坐标信息，生成人员活动轨迹，可切换时间和播放速率，在地图中查看'
        },
        {
          label: '排班管理：',
          content: '满足项目管理需求，可设置多个排班时间段，针对作业人员按天、按时间段进行人员日常排班管理'
        },
        {
          label: '在岗统计：',
          content: '通过人员绑定的智能设备定位信息以及项目作业区域电子围栏，统计人员在岗时长。依据配置的在岗时长要求，生成项目下人员每日在岗统计信息'
        },
        {
          label: '实名制考勤：',
          content: '基于人脸识别AI技术实现项目实名制考勤及上工人数智能计算'
        }
      ],
      hardwareList: ['智能手环', '智慧工牌'],
      softwareList: ['用工管理'],
      codes: ['yhWorkerHire']
    }
  ]

  get index () {
    return this.$route.params.id || ''
  }

  created () {
    this.detail = this.detailList[Number(this.index)]
  }

  toRemove () {
    this.$axios.post(this.$apis.common.insertCustomerAbilityCodes, this.detail.codes).then(() => {
      this.centerDialogVisible = true
    })
  }

  closeDialog () {
    this.$message.success('开通成功')
    this.$router.back()
  }
}
